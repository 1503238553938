<template>
  <div class="h-screen flex flex-col justify-center">
    <form class="w-full max-w-sm mx-auto px-4" @submit.prevent="submitLogin">
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        contimo
        <hr class="ml-4 flex flex-1">
      </CTypo>
      <CTextField
        v-model="email"
        type="email"
        :label="$t('email')"
        required
      />
      <CTextField
        v-model="password"
        ref="passwordField"
        type="password"
        :label="$t('password')"
        :variant="loginError ? 'danger' : 'default'"
        :autofocus="savedLoginEmail"
        required
        password-reveal
        @focus="onPasswordFocus"
      >
        <template v-slot:message>
          <div v-if="loginError">
            <span>
              {{ $t('errorMessages.emailAndOrPasswordIsWrong') }}
            </span>
            <CLink to="/request-password-reset" class="text-gray-600" small>
              {{ $t('passwordForgotten') }}
            </CLink>
          </div>
        </template>
      </CTextField>
      <CLink v-if="!loginError" to="/request-password-reset" class="text-gray-600 mb-4">
        {{ $t('passwordForgotten') }}
      </CLink>
      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
        :loading="isLoginLoading"
      >
        {{ $t('login') }}
      </CButton>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { IS_CUSTOMER, IS_LOGIN_LOADING, IS_MANAGER, ROUTE_AFTER_LOGIN } from '@/store/gettersTypes';
import { SET_APP_LOADING, SET_NEXT_ROUTE } from '@/store/mutationsTypes';

export default {
  data: () => ({
    email: null,
    password: null,
    loginError: false,
  }),

  computed: {
    savedLoginEmail() {
      return this.$store.state.auth.savedLoginEmail;
    },
    ...mapGetters([IS_MANAGER, IS_CUSTOMER, ROUTE_AFTER_LOGIN, IS_LOGIN_LOADING]),
  },

  watch: {
    savedLoginEmail: {
      immediate: true,
      handler(newVal) {
        this.email = newVal;
      },
    },
  },

  methods: {
    ...mapMutations([SET_APP_LOADING, SET_NEXT_ROUTE]),
    submitLogin() {
      this.loginError = false;
      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(() => {
          this.setAppLoading(true);
          setTimeout(() => {
            this.email = null;
            this.password = null;
            if (this.routeAfterLogin) {
              this.$router.push(this.routeAfterLogin);
            } else if (this.isManager) {
              this.$router.push('/m/home');
            }
            this.setNextRoute(null);
            setTimeout(() => {
              this.setAppLoading(false);
            }, 500);
          }, 300);
        })
        .catch(() => {
          this.loginError = true;
          this.$refs.passwordField.blur();
          this.password = null;
        });
    },
    onPasswordFocus() {
      if (this.loginError) {
        this.loginError = false;
      }
    },
  },
};
</script>
