<template>
  <div class="h-screen flex flex-col justify-center">
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submitInviteAcception"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        {{ $t('acceptInvite') }}<hr class="ml-4 flex flex-1">
      </CTypo>
      <CTextField
        v-model="signupData.firstName"
        :label="$t('firstName')"
        autocomplete="given-name"
        required
      />
      <CTextField
        v-model="signupData.lastName"
        :label="$t('lastName')"
        autocomplete="family-name"
        required
      />
      <CTextField
        v-model="email"
        type="email"
        :label="$t('email')"
        required
        disabled
      />
      <CPasswordInputs ref="passwordInputs" v-model="passwordInputs" />
      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
      >
        {{ $t('acceptInvite') }}
      </CButton>
    </form>
    <CModal v-model="showErrorModal" custom-footer>
      <template v-slot:header>
        {{ $t('failedInviteAcception.header') }}
      </template>
      <CAlert variant="danger">
        {{ $t('failedInviteAcception.message') }}
      </CAlert>
      <template v-slot:footer-right>
        <CButton @click="showErrorModal = false" variant="primary">{{ $t('ok') }}</CButton>
      </template>
    </CModal>
    <CModal v-model="showSuccessModel" custom-footer no-cancel>
      <template v-slot:header>
        {{ $t('successfulInviteAcception.header') }}
      </template>
      <CAlert variant="success">
        {{ $t('successfulInviteAcception.message') }}
      </CAlert>
      <template v-slot:footer-right>
        <router-link to="/login">
          <CButton variant="primary">{{ $t('loginNow') }}</CButton>
        </router-link>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SIGNUP_INVITED_MANAGER } from '@/store/actionTypes';

export default {
  data: () => ({
    signupData: {
      firstName: '',
      lastName: '',
      termsVersion: '20210524',
      type: 'manager',
      password: '',
    },
    passwordInputs: {
      password: '',
      passwordRepeat: '',
    },
    showErrorModal: false,
    showSuccessModel: false,
  }),

  computed: {
    email() {
      return this.$route.params.email;
    },
    signature() {
      return this.$route.query.signature;
    },
  },

  methods: {
    ...mapActions([SIGNUP_INVITED_MANAGER]),

    async submitInviteAcception() {
      const valid = this.$refs.passwordInputs.validate();
      if (valid) {
        this.signupData.password = this.passwordInputs.password;
        try {
          await this[SIGNUP_INVITED_MANAGER]({
            signupData: this.signupData,
            email: this.email,
            signature: this.signature,
          });
          this.showSuccessModel = true;
        } catch (e) {
          this.showErrorModal = true;
        }
      }
    },
  },
};
</script>
